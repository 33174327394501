import { toFormValidator } from '@vee-validate/zod'
import * as zod from 'zod'

export const validationSchema = toFormValidator(zod.object({
    oldPassword: zod.string().regex(/(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).+/, { message: 'Please use a minimum of 6 characters with at least 1 uppercase, 1 lowercase, and 1 number.' }),
    newPassword: zod.string().regex(/(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).+/, { message: 'Please use a minimum of 6 characters with at least 1 uppercase, 1 lowercase, and 1 number.' }),
    confirmPassword: zod.string()
}).refine(data => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword']
})
)
